import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function BlogPost() {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    async function fetchPost() {
      const response = await axios.get(`https://l5wrjoqe5g.execute-api.us-east-1.amazonaws.com/dev/posts/${id}`);
      setPost(response.data);
    }
    fetchPost();
  }, [id]);

  if (!post) return <div>Loading...</div>;

  return (
    <div>
      <h1 className="mb-4">{post.title}</h1>
      <p>{post.content}</p>
    </div>
  );
}

export default BlogPost;
