import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BlogList from './BlogList';
import BlogPost from './BlogPost';
import NewPost from './NewPost';
import NavBar from './NavBar';
import Intro from './Intro';
import About from './About';
import Skills from './Skills';
import WorkExperience from './WorkExperience';
import Education from './Education';
import ContactForm from './ContactForm';

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-3">
              <img src={`${process.env.PUBLIC_URL}/profile.jpg`} alt="Your Name" className="img-fluid rounded-circle mb-4" />
            </div>
            <div className="col-md-9">
              <Routes>
                <Route path="/" element={<BlogList />} />
                <Route path="/post/:id" element={<BlogPost />} />
                <Route path="/new" element={<NewPost />} />
                <Route path="/intro" element={<Intro />} />
                <Route path="/about" element={<About />} />
                <Route path="/skills" element={<Skills />} />
                <Route path="/experience" element={<WorkExperience />} />
                <Route path="/education" element={<Education />} />
                <Route path='/contactForm' element={<ContactForm/>}/>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
