import React from 'react';

function Intro() {
    return (
        <div>
            <h1>Welcome to My Portfolio</h1>
            <p>As a Full-Stack Java Developer with over 5 years of professional experience, I bring a wealth of technical expertise and a strong foundation in Java 8, Spring Boot, RESTFUL APIs and Microservices development, enabling me to contribute effectively to long-term projects. My proficiency extends to developing user interfaces using modern web technologies like React.js, and Bootstrap. I have a deep understanding of Event-Driven Design (EDD), Domain Driven Design(DDD), strong experience on Test driven design (TDD) approach using JUnit and log4j, accompanied with hands-on experience with AWS services. My adaptable and collaborative nature allows me to excel in dynamic team environments, while my problem-solving skills and attention to detail ensure the delivery of high-quality solutions. With a commitment to staying current with industry trends, I am poised to drive innovation and contribute significantly to the success of projects.</p>
        </div>
    );
}

export default Intro;
