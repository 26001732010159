import React, { useState } from 'react';
import axios from 'axios';

function ContactForm() {
    const [formData, setFormData] = useState({
        messageTitle: '',
        message: '',
        email: '',
        guestName: '',
        phone: ''
    });
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState({
        phone: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'phone') {
            validatePhone(value);
        }
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        if (phone && !phoneRegex.test(phone)) {
            setErrors({ ...errors, phone: 'Phone number must be 10 digits.' });
        } else {
            setErrors({ ...errors, phone: '' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.messageTitle || !formData.message || !formData.email || !formData.guestName) {
            alert('Please fill in all required fields.');
            return;
        }

        if (errors.phone) {
            alert('Please correct the errors before submitting.');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post('https://l5wrjoqe5g.execute-api.us-east-1.amazonaws.com/dev/contacts', formData);
            setFormData({
                messageTitle: '',
                message: '',
                email: '',
                guestName: '',
                phone: ''
            });
            if (response.status >= 200 && response.status < 300) {
                setSuccessMessage('Your message has been sent successfully!');
            } else {
                setSuccessMessage('There was a problem sending the message!');
            }
        } catch (error) {
            alert('Failed to send message: ' + error.message);
        }
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Contact Us</h2>
            {loading && <p>Loading...</p>}
            {successMessage && <p>{successMessage}</p>}
            <div className="form-group">
                <label htmlFor="messageTitle">Message Title *</label>
                <input
                    type="text"
                    className="form-control"
                    id="messageTitle"
                    name="messageTitle"
                    value={formData.messageTitle}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="message">Message *</label>
                <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                ></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="email">Email *</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="guestName">Guest Name *</label>
                <input
                    type="text"
                    className="form-control"
                    id="guestName"
                    name="guestName"
                    value={formData.guestName}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="phone">Phone (optional)</label>
                <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                />
                {errors.phone && <small className="text-danger">{errors.phone}</small>}
            </div>
            <button type="submit" className="btn btn-primary">Send Message</button>
        </form>
    );
}

export default ContactForm;
