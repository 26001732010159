import React from 'react';

function Skills() {
    return (
        <div>
            <h2>Skills</h2>
            <ul>
                <li>Java</li>
                <li>Spring Boot</li>
                <li>React & Redux</li>
                <li>Angular</li>
                <li>MySQL</li>
                <li>Docker</li>
                <li>AWS</li>
                <li>Git & GitHub</li>
                <li>RESTful APIs</li>
            </ul>
        </div>
    );
}

export default Skills;
