import React from 'react';

function Intro() {
    return (
        <div>
            <h1>Welcome to My Portfolio</h1>
            <p>Hi, I'm [Your Name]!</p>
        </div>
    );
}

export default Intro;
