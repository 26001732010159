import React from 'react';

function Education() {
    return (
        <div>
            {/* <h2>Education</h2> */}
            <div>
                <h3>Master of Science in Computer Science</h3>
                <p>Maharishi International University - Jan 2023 - Nov 2025</p>
            </div>
            <div>
                <h3>Bachelor of Science in Biomedical Engineering</h3>
                <p>Makerere Universityt - Aug 2017 - Jan 2018</p>
            </div>
        </div>
    );
}

export default Education;
