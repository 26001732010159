import React from 'react';

function WorkExperience() {
    return (
        <div>
            <h2>Work Experience</h2>
            <div>
                <h3>Full Stack Java Developer – HIREART INC, New York NY, USA</h3>
                <p>Dec 2023 - May 2025</p>
                <ul>
                    <li>Spearheaded the development of Java web-based applications, significantly enhancing coding efficiency and accuracy, resulting in a 30% improvement in system performance.</li>
                    <li>Integrated robust user interfaces utilizing Spring frameworks and led the adoption of Spring Boot and AWS for backend services, boosting API efficiency and reliability for real-time AI applications.</li>
                    <li>Championed best practices in Java code quality and security across complex Java systems, notably reducing system vulnerabilities by 50% and ensuring compliance with industry standards, including experience in an Oracle environment.</li>
                    <li>Developed and maintained high-volume/load, high-performance hosted applications, implementing comprehensive unit and integration tests that achieved over 85% code coverage, which minimized bugs by 40% and enhanced deployment cycles</li>
                </ul>
            </div>
            <div>
                <h3>Full Stack Java Developer – Shunem SYstems Limited</h3>
                <p>March 2018 - Jan 2023</p>
                <ul>
                    <li>Developed and maintained web applications using Java, Spring Framework, and Hibernate ORM.</li>
                    <li>Enhanced a major project's scalability and performance by 25% through core functionality optimization using JDK, enabling support for 30% more concurrent users.</li>
                    <li>Led and advanced more than 10 projects as a full-stack developer during nearly 5 years, consistently achieving a 20% reduction in project completion times, driving increased efficiency and delivery excellence</li>
                </ul>
            </div>
        </div>
    );
}

export default WorkExperience;
