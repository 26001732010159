import React, { useState } from 'react';
import axios from 'axios';

function NewPost() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null); // For success or error messages

    const validateInput = () => {
        if (!title.trim() || !content.trim() || password !== 'timothy_password') {
            setMessage('Please check your inputs. Title and content cannot be empty, and the password must be correct.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(null);
        if (!validateInput()) {
            return;
        }

        try {
            const post = { title, content };
            const response = await axios.post('https://l5wrjoqe5g.execute-api.us-east-1.amazonaws.com/dev/posts', post);
            setTitle('');
            setContent('');
            setPassword('');
            setMessage('Post created successfully!');
        } catch (error) {
            setMessage(`Error creating post: ${error.message}`);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="content">Content</label>
                <textarea
                    className="form-control"
                    id="content"
                    rows="5"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                ></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            {message && <div className="alert alert-info">{message}</div>}
            <button type="submit" className="btn btn-primary">Create Post</button>
        </form>
    );
}

export default NewPost;
